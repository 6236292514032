/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable plugin/no-unsupported-browser-features */
/* stylelint-enable plugin/no-unsupported-browser-features */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1uxbz_n1ik6_151:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-pa3mqb, 14px);
  line-height: var(--line-height-body-m-2zx78l, 22px);
  color: var(--color-text-body-default-ffdwgg, #16191f);
  font-weight: 400;
  font-family: var(--font-family-base-4om3hr, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  color: var(--color-text-body-default-ffdwgg, #16191f);
  background-color: var(--color-background-layout-main-td853a, #f2f3f3);
  --awsui-max-content-width-7t5ed6: 100%;
  display: grid;
  grid-template-areas: "topBar    topBar     topBar" ".      notifications ." ".          main      .";
  grid-template-columns: var(--space-layout-content-horizontal-sin989, 40px) 1fr var(--space-layout-content-horizontal-sin989, 40px);
  grid-template-rows: min-content min-content 1fr;
}
@media (min-width: 689px) {
  .awsui_root_1uxbz_n1ik6_151:not(#\9) {
    grid-template-areas: "navigation topBar       topBar        topBar    tools" "navigation .         notifications    .         tools" "navigation .             main         .         tools";
    grid-template-columns: min-content minmax(var(--space-layout-content-horizontal-sin989, 40px), 1fr) minmax(0, var(--awsui-max-content-width-7t5ed6)) minmax(var(--space-layout-content-horizontal-sin989, 40px), 1fr) min-content;
    grid-template-rows: min-content min-content 1fr min-content;
  }
}
@media (min-width: 689px) and (min-width: 1401px) {
  .awsui_root_1uxbz_n1ik6_151.awsui_has-adaptive-widths-default_1uxbz_n1ik6_200:not(#\9) {
    --awsui-max-content-width-7t5ed6: 1280px;
  }
}
@media (min-width: 689px) and (min-width: 1921px) {
  .awsui_root_1uxbz_n1ik6_151.awsui_has-adaptive-widths-default_1uxbz_n1ik6_200:not(#\9) {
    --awsui-max-content-width-7t5ed6: 1440px;
  }
}
@media (min-width: 689px) and (min-width: 2541px) {
  .awsui_root_1uxbz_n1ik6_151.awsui_has-adaptive-widths-default_1uxbz_n1ik6_200:not(#\9) {
    --awsui-max-content-width-7t5ed6: 1620px;
  }
}
@media (min-width: 689px) and (min-width: 1401px) {
  .awsui_root_1uxbz_n1ik6_151.awsui_has-adaptive-widths-dashboard_1uxbz_n1ik6_215:not(#\9) {
    --awsui-max-content-width-7t5ed6: 1280px;
  }
}
@media (min-width: 689px) and (min-width: 1921px) {
  .awsui_root_1uxbz_n1ik6_151.awsui_has-adaptive-widths-dashboard_1uxbz_n1ik6_215:not(#\9) {
    --awsui-max-content-width-7t5ed6: 1620px;
  }
}
@media (min-width: 689px) and (min-width: 2541px) {
  .awsui_root_1uxbz_n1ik6_151.awsui_has-adaptive-widths-dashboard_1uxbz_n1ik6_215:not(#\9) {
    --awsui-max-content-width-7t5ed6: 2160px;
  }
}

.awsui_navigation_1uxbz_n1ik6_230:not(#\9),
.awsui_tools_1uxbz_n1ik6_231:not(#\9) {
  grid-row: 1/-1;
  grid-column: 1/-1;
  background: var(--color-background-container-content-sgemmm, #ffffff);
  z-index: 1;
}

@media (min-width: 689px) {
  .awsui_navigation_1uxbz_n1ik6_230:not(#\9) {
    grid-area: navigation;
    inline-size: var(--awsui-navigation-width-7t5ed6);
    border-inline-end: 1px solid var(--color-border-divider-panel-side-btgups, transparent);
  }
}

@media (min-width: 689px) {
  .awsui_tools_1uxbz_n1ik6_231:not(#\9) {
    grid-area: tools;
    inline-size: var(--awsui-tools-width-7t5ed6);
    border-inline-start: 1px solid var(--color-border-divider-panel-side-btgups, transparent);
  }
}

.awsui_top-bar_1uxbz_n1ik6_254:not(#\9) {
  grid-area: topBar;
  block-size: 48px;
  border-block-end: 1px solid var(--color-border-divider-panel-bottom-sc2k0v, rgba(0, 28, 36, 0.15));
}

.awsui_notifications_1uxbz_n1ik6_260:not(#\9) {
  grid-area: notifications;
}
.awsui_notifications_1uxbz_n1ik6_260:not(#\9):not(:empty) {
  padding-block-start: var(--space-scaled-xs-x91h81, 8px);
}

.awsui_main-landmark_1uxbz_n1ik6_267:not(#\9) {
  display: contents;
}

.awsui_main_1uxbz_n1ik6_267:not(#\9) {
  grid-area: main;
  margin-block-start: var(--space-scaled-s-9q8n2q, 12px);
  margin-block-end: var(--space-layout-content-bottom-ve4osc, 20px);
}
.awsui_main-disable-paddings_1uxbz_n1ik6_276:not(#\9) {
  grid-column: 1/-1;
  margin-block: 0;
}
@media (min-width: 689px) {
  .awsui_main-disable-paddings_1uxbz_n1ik6_276:not(#\9) {
    grid-column: 2/span 3;
  }
}

.awsui_content-header_1uxbz_n1ik6_286:not(#\9) {
  margin-block-end: var(--space-content-header-padding-bottom-d3iv03, 16px);
}